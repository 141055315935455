'use client';

import { FC, MouseEventHandler, useState } from 'react';
import { ProfileButton } from '@ghosts-inc/design-system/components';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import { AnalyticsEventNames } from '@/analytics/Event.types';
import { analytics } from '@/analytics/segment';
import MenuItem, { type MenuItemProps } from '@/components/MenuItem';
import { handleSignOut } from '@/components/UserMenu/actions';
import { ReferrerStates } from '@/legacy/referrers/referrerSummary.model';
import { SellerStates } from '@/legacy/sellers/sellerSummary.model';
import { useGhostStore } from '@/legacy/store';

interface UserMenuProps {
  isReferralEnabled: boolean;
}

const UserMenuItem: FC<MenuItemProps & { title: string }> = ({ title, ...props }) => {
  return (
    <MenuItem {...props}>
      <Typography>{title}</Typography>
    </MenuItem>
  );
};

const UserMenu: FC<UserMenuProps> = ({ isReferralEnabled }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { user, setUser } = useGhostStore();

  if (!user) {
    return null;
  }

  const initials = `${user.firstName[0]}${user.lastName[0]}`;

  const showMenu: MouseEventHandler<HTMLDivElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const logOut = async () => {
    analytics.track(AnalyticsEventNames.LOGOUT_CLICKED);
    await handleSignOut();
    closeMenu();
    setUser(null);
  };

  const isSeller = user.seller?.state === SellerStates.ACTIVATED;
  const isReferrer = isReferralEnabled && user.referrer?.state === ReferrerStates.ACTIVATED;

  return (
    <>
      <ProfileButton data-testid="user-menu-button" onClick={showMenu} selected={open}>
        {initials}
      </ProfileButton>
      <Menu
        data-testid="user-menu-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={open}
        onClose={closeMenu}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <UserMenuItem href="/preferences" title="Preferences" onClick={closeMenu} />
        {isSeller && (
          <UserMenuItem
            href="/seller"
            title="Switch to Seller"
            target="_blank"
            onClick={closeMenu}
          />
        )}
        {isReferrer && (
          <UserMenuItem
            href="/referrer"
            title="Switch to Referrer"
            target="_blank"
            onClick={closeMenu}
          />
        )}
        <UserMenuItem onClick={logOut} title="Sign Out" />
      </Menu>
    </>
  );
};

export default UserMenu;
