import { FC, FormEventHandler, use } from 'react';
import { Search } from '@ghosts-inc/design-system/icons';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { SearchBoxContext } from './SearchBoxContext';

const SearchBox: FC = () => {
  const { searchTerm, setSearchTerm, search } = use(SearchBoxContext);

  const handleSubmit: FormEventHandler = (event) => {
    event.preventDefault();
    const term = searchTerm.trim();
    if (term === '') return;
    search(term);
  };

  return (
    <form data-testid="header-search-form" onSubmit={handleSubmit}>
      <TextField
        autoComplete="off"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search htmlColor="black" />
            </InputAdornment>
          ),
        }}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Search brands or products"
        data-testid="input-searchBox"
        size="small"
        fullWidth={true}
        variant="standard"
        value={searchTerm}
      />
    </form>
  );
};

export default SearchBox;
