import React, { useEffect, useState } from 'react';
import { DotFilled } from '@ghosts-inc/design-system/icons';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useRouter } from 'next/navigation';
import { AnalyticsEventNames } from '@/analytics/Event.types';
import { AnalyticsPageNames } from '@/analytics/Page.types';
import { analytics } from '@/analytics/segment';
import Link from '@/components/Link';
import { DealsCountForBuyer, DisplayedDealStatusForBuyer } from '@/legacy/offers/constants';
import { getDisplayedDealStatusForBuyer } from './actions';
import { BuyerOffersList } from './types';

const OFFERS_PAGE_LIST: Record<DisplayedDealStatusForBuyer, string> = {
  [DisplayedDealStatusForBuyer.NEEDS_REVIEW]: 'Action Required',
  [DisplayedDealStatusForBuyer.DRAFT]: 'Offers in Draft',
  [DisplayedDealStatusForBuyer.PENDING]: 'Pending Offers',
  [DisplayedDealStatusForBuyer.COMPLETED]: 'Completed Offers',
  [DisplayedDealStatusForBuyer.ARCHIVED]: 'Archived',
};

const DEFAULT_OFFERS_COUNT: DealsCountForBuyer = {
  [DisplayedDealStatusForBuyer.NEEDS_REVIEW]: 0,
  [DisplayedDealStatusForBuyer.DRAFT]: 0,
  [DisplayedDealStatusForBuyer.PENDING]: 0,
  [DisplayedDealStatusForBuyer.COMPLETED]: 0,
  [DisplayedDealStatusForBuyer.ARCHIVED]: 0,
};

export const MyOffers = () => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const [buyerOffersList, setBuyerOffersList] = useState<BuyerOffersList>();

  useEffect(() => {
    getDisplayedDealStatusForBuyer().then((list) => setBuyerOffersList(list));
  }, []);

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const handleOpen = async () => {
    setBuyerOffersList(await getDisplayedDealStatusForBuyer());
    setOpen(true);
  };

  const offersData: DealsCountForBuyer = Object.values(
    DisplayedDealStatusForBuyer,
  ).reduce<DealsCountForBuyer>((acc, status) => {
    acc[status] = buyerOffersList?.[status] ?? 0;
    return acc;
  }, DEFAULT_OFFERS_COUNT);

  const router = useRouter();

  return (
    <div onMouseEnter={handleOpen} onMouseLeave={() => setOpen(false)}>
      <Button
        onClick={() => {
          analytics.track(AnalyticsEventNames.NAV_ICON_CLICKED, {
            to: AnalyticsPageNames.BUYER_OFFERS,
          });
          router.push('/offers');
        }}
        startIcon={
          offersData[DisplayedDealStatusForBuyer.NEEDS_REVIEW] > 0 && (
            <DotFilled color="primary" sx={{ width: 8, height: 8 }} />
          )
        }
        endIcon={<KeyboardArrowDownIcon />}
        size="small"
        variant="outlined"
        color="buttonLight"
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
      >
        <Typography variant="bodySmallLight">My Offers</Typography>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-end"
        transition
        disablePortal
        sx={{ elevation: 2, position: 'relative', zIndex: 1 }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  style={{ width: '220px', padding: 8, marginTop: '13px' }}
                >
                  {Object.entries(OFFERS_PAGE_LIST).map(([offerState, offerStateLabel]) => {
                    // We don't want to show the "Needs Review" tab if there are no offers that need review
                    if (
                      offerState === DisplayedDealStatusForBuyer.NEEDS_REVIEW &&
                      offersData[offerState] === 0
                    )
                      return;

                    return (
                      <Link
                        underline="none"
                        href={`/offers?status=${offerState}`}
                        rel="noopener noreferrer"
                        onClick={handleClose}
                        key={offerState}
                      >
                        <MenuItem onClick={handleClose} style={{ minHeight: '40px' }}>
                          <Stack
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="center"
                            width="100%"
                          >
                            <Typography variant="bodyLargeLight">{offerStateLabel}</Typography>

                            {offerState === DisplayedDealStatusForBuyer.NEEDS_REVIEW &&
                            offersData[offerState] > 0 ? (
                              <Chip
                                variant="badge"
                                label={offersData[offerState]}
                                sx={{ '&.MuiChip-root': { px: 0.5, minWidth: 20 } }}
                              />
                            ) : (
                              <Typography px={0.5}>
                                {offersData[offerState as DisplayedDealStatusForBuyer]}
                              </Typography>
                            )}
                          </Stack>
                        </MenuItem>
                      </Link>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
