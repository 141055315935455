'use client';

import { AnalyticsEventNames } from '@/analytics/Event.types';
import { analytics } from '@/analytics/segment';
import Link from '@/components/Link';
import { MENU_ITEMS, MenuItemConfig, makeLink } from './menuItems';

const onLinkClick = (args: (typeof MENU_ITEMS)[0] & { index: number }) => {
  analytics.track(AnalyticsEventNames.NAV_LINK_CLICKED, {
    collection: args.collection,
    category_group: args.categoryGroup,
    display_text: args.displayText,
    index: args.index,
  });
};

const CategoryBannerLink = ({
  displayText,
  collection,
  categoryGroup,
  index,
}: { index: number } & MenuItemConfig): JSX.Element => {
  const link = makeLink(collection, categoryGroup);

  return (
    <Link
      color="typographyDarkInverted"
      underline="none"
      href={link}
      data-testid={displayText}
      onClick={() => {
        onLinkClick({ collection, categoryGroup, displayText, index });
      }}
      variant="linkCaps"
      sx={{
        '&:hover': {
          color: 'surface.main',
        },
      }}
    >
      {displayText}
    </Link>
  );
};

export default CategoryBannerLink;
