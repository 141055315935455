import {
  CategoryGroup,
  Collection,
} from '@/legacy/listings/ListingsPage/v2/ListingsFilterPage/filterPageConfigs';

export type MenuItemConfig = {
  displayText: string;
  collection: Collection;
  categoryGroup?: CategoryGroup;
};

export const MENU_ITEMS: MenuItemConfig[] = [
  {
    displayText: 'NEW',
    collection: 'new',
    categoryGroup: undefined,
  },
  {
    displayText: 'FEATURED',
    collection: 'featured',
    categoryGroup: undefined,
  },
  {
    displayText: 'TRENDING',
    collection: 'trending',
    categoryGroup: undefined,
  },
  {
    displayText: 'APPAREL',
    collection: 'all',
    categoryGroup: 'apparel',
  },
  {
    displayText: 'ACCESSORIES',
    collection: 'all',
    categoryGroup: 'accessories',
  },
  {
    displayText: 'BEAUTY',
    collection: 'all',
    categoryGroup: 'beauty',
  },
  {
    displayText: 'FOOTWEAR',
    collection: 'all',
    categoryGroup: 'footwear',
  },
  {
    displayText: 'HOUSEWARES',
    collection: 'all',
    categoryGroup: 'housewares',
  },
];

export const makeLink = (collection: Collection, categoryGroup?: CategoryGroup) => {
  if (!categoryGroup) {
    return `/collection/${collection}`;
  }

  return `/collection/${collection}/${categoryGroup}`;
};
