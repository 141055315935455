'use client';

import { HeartOutline } from '@ghosts-inc/design-system/icons';
import IconButton from '@mui/material/IconButton';
import { AnalyticsEventNames } from '@/analytics/Event.types';
import { AnalyticsPageNames } from '@/analytics/Page.types';
import { analytics } from '@/analytics/segment';
import Link from '@/components/Link';

export default function FavoritesButton(): JSX.Element {
  return (
    <IconButton
      LinkComponent={Link}
      href="/saved"
      data-testid="button-savedIcon"
      onClick={() => {
        analytics.track(AnalyticsEventNames.NAV_ICON_CLICKED, {
          to: AnalyticsPageNames.BUYER_FAVORITES,
        });
      }}
      sx={{
        '&:hover': {
          backgroundColor: 'surface.lighter',
        },
      }}
    >
      <HeartOutline />
    </IconButton>
  );
}
