'use client';

import { useContext } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Banner from '../Banner';
import { ArrearsContext } from './ArrearsContext';
import { OverdueStatus } from './types';

function getArrearsMessage(overdueStatus: OverdueStatus) {
  return overdueStatus === OverdueStatus.WARNING ? (
    <Typography display="inline">Please pay your overdue balance to avoid offer delays.</Typography>
  ) : (
    <Typography display="inline">
      Your payment is beyond{' '}
      <Typography component="span" fontWeight="bold">
        30 days overdue.
      </Typography>{' '}
      Pay to avoid delays.
    </Typography>
  );
}

function getOffersHref(dealCycleIds: number[]) {
  return dealCycleIds.length > 1 ? '/offers' : `/offers/${dealCycleIds[0]}`;
}

function getStyles(theme: Theme, overdueStatus: OverdueStatus) {
  return overdueStatus === OverdueStatus.WARNING
    ? {
        bgcolor: theme.palette.warning.lightest,
        color: theme.palette.warning.darker,
        buttonColor: '#562200',
      }
    : {
        bgcolor: theme.palette.error.lightest,
        color: theme.palette.error.darker,
        buttonColor: '#5E100C',
      };
}

export function ArrearsBannerMain() {
  const theme = useTheme();
  const { dealCycleIds, overdueStatus, showArrearsBanner } = useContext(ArrearsContext);
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.between('mobile', 'desktop'));

  const bannerMessage = getArrearsMessage(overdueStatus);
  const href = getOffersHref(dealCycleIds);
  const styles = getStyles(theme, overdueStatus);

  return (
    <>
      {showArrearsBanner && (
        <Banner
          bgcolor={styles.bgcolor}
          color={styles.color}
          paddingX={isMobileOrTablet ? 2 : 0}
          paddingY={isMobileOrTablet ? 1 : 0}
          spacing={1}
        >
          <div>{bannerMessage}</div>
          <Button
            href={href}
            variant="contained"
            size="small"
            style={{
              backgroundColor: styles.buttonColor,
              minWidth: 'fit-content',
            }}
          >
            Pay Now
          </Button>
        </Banner>
      )}
    </>
  );
}

export function ArrearsBannerModal() {
  const theme = useTheme();
  const { dealCycleIds, overdueStatus, showArrearsBanner } = useContext(ArrearsContext);

  const bannerMessage = getArrearsMessage(overdueStatus);
  const href = getOffersHref(dealCycleIds);
  const styles = getStyles(theme, overdueStatus);

  return (
    <>
      {showArrearsBanner && (
        <Box mb={2.5}>
          <Banner bgcolor={styles.bgcolor} color={styles.color} padding={1.75} borderRadius={1.5}>
            <Box paddingRight={1} paddingLeft={1}>
              {bannerMessage}
            </Box>
            <Button
              href={href}
              variant="contained"
              size="small"
              style={{ backgroundColor: styles.buttonColor, minWidth: 'fit-content' }}
            >
              Pay Now
            </Button>
          </Banner>
        </Box>
      )}
    </>
  );
}
