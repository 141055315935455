'use client';

import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { usePathname } from 'next/navigation';

export default function HideOnScroll({ children }: { children: React.ReactElement }): JSX.Element {
  const trigger = useScrollTrigger();
  const pathname = usePathname();
  const isOffersDetailsPage = pathname.startsWith('/offers/');

  return (
    <>
      {isOffersDetailsPage ? (
        <Stack>{children}</Stack>
      ) : (
        <Slide appear={false} direction="down" in={!trigger}>
          {children}
        </Slide>
      )}
    </>
  );
}
