'use client';

import { FC, use, useEffect } from 'react';
import { DotFilled } from '@ghosts-inc/design-system/icons';
import { ButtonProps } from '@mui/material';
import Button from '@mui/material/Button';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { AnalyticsEventNames } from '@/analytics/Event.types';
import { analytics } from '@/analytics/segment';
import { InboxButtonContext } from '@/app/(authenticated)/(marketplace)/HeaderNew/_components/inboxButtonContext';
import { useGhostStore } from '@/legacy/store';

const InboxButton: FC<Pick<ButtonProps, 'variant' | 'size'>> = ({ ...props }) => {
  const { hasUnreadMessages, refresh } = use(InboxButtonContext);
  const currentUser = useGhostStore((state) => state.user);

  const pathname = usePathname();
  useEffect(() => {
    refresh();
  }, [pathname, refresh]);

  return (
    <Button
      LinkComponent={Link}
      href="/inbox"
      startIcon={
        hasUnreadMessages && (
          <DotFilled
            data-testid="inbox-unread-dot"
            sx={(theme) => ({ width: 8, color: theme.palette.newBrandFill.default })}
          />
        )
      }
      onClick={() => {
        analytics.track(
          hasUnreadMessages
            ? AnalyticsEventNames.BUYER_CLICKED_INBOX_BUTTON_WITH_UNREAD_MSGS
            : AnalyticsEventNames.BUYER_CLICKED_INBOX_BUTTON_WITHOUT_UNREAD_MSGS,
          {
            buyer_id: currentUser?.buyer?.id,
            user_id: currentUser?.id,
          },
        );
      }}
      {...props}
    >
      Inbox
    </Button>
  );
};

export default InboxButton;
