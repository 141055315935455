import { CategoryGroup, Collection } from './ListingsFilterPage/filterPageConfigs';

export const getHomePageLink = () => {
  return '/';
};

export const getCollectionPageLink = (collection: Collection) => {
  return `/collection/${collection}`;
};

export const getCategoryGroupPageLink = (collection: Collection, categoryGroup: CategoryGroup) => {
  const link = `${getCollectionPageLink(collection)}/${categoryGroup}`;
  return link;
};

export const getRecommendationPageLink = (filterAttribute: string) => {
  const link = `/recommendations/${filterAttribute}`;
  return link;
};
