'use client';

import { useState } from 'react';
import { ghostTheme } from '@ghosts-inc/design-system';
import { GhostLogo } from '@ghosts-inc/design-system/svgs';
import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useSearchParams } from 'next/navigation';
import { BuyerListingRecommendations } from '@/app/(authenticated)/(marketplace)/inbox/types';
import Link from '@/components/Link';
import { UserMenu } from '@/components/UserMenu';
import CategoryMenu from './CategoryMenu';
import FavoritesButton from './FavoritesButton';
import InboxButton from './InboxButton';
import { MyOffers } from './MyOffers';
import OffersButton from './OffersButton';
import SearchBox from './SearchBox';

export default function Header({
  isAmRecsEnabled,
  productsIndexName,
  recommendationsPromise,
}: {
  isAmRecsEnabled: boolean;
  productsIndexName: string;
  recommendationsPromise: Promise<BuyerListingRecommendations[]>;
}): JSX.Element {
  const params = useSearchParams();
  const query = params.get(`${productsIndexName}[query]`) || '';
  const [searchTerm, setSearchTerm] = useState<string>(query);
  const isMobile = useMediaQuery(ghostTheme.breakpoints.only('mobile'));

  return (
    <>
      <Stack
        direction="row"
        px={{ mobile: 2, desktop: 10 }}
        justifyContent="space-between"
        alignItems="center"
        gap={2}
      >
        <Link href="/">
          <GhostLogo height={33} />
        </Link>

        <Box flexGrow={1} maxWidth={600} display={{ mobile: 'none', desktop: 'flex' }}>
          <SearchBox
            onChange={setSearchTerm}
            searchTerm={searchTerm}
            productsIndexName={productsIndexName}
          />
        </Box>

        <Stack direction="row" gap={2} marginY="auto" alignItems="center">
          {isMobile ? (
            <OffersButton />
          ) : (
            <Box>
              <MyOffers />
            </Box>
          )}

          {isAmRecsEnabled && <InboxButton recommendationsPromise={recommendationsPromise} />}
          <Box display={{ mobile: 'none', desktop: 'flex' }}>
            <UserMenu />
          </Box>
          <FavoritesButton />

          {/* IconButton doesn't take display prop */}
          <Box display={{ mobile: 'flex', desktop: 'none' }}>
            <CategoryMenu />
          </Box>
        </Stack>
      </Stack>
      <Box
        mx={'auto'}
        width="100%"
        px={{ mobile: 2, desktop: 10 }}
        display={{ mobile: 'flex', desktop: 'none' }}
        alignContent={'center'}
      >
        <SearchBox
          onChange={setSearchTerm}
          searchTerm={searchTerm}
          productsIndexName={productsIndexName}
        />
      </Box>
    </>
  );
}
