'use client';

import { useEffect, useState } from 'react';
import { DotFilled, Offers } from '@ghosts-inc/design-system/icons';
import IconButton from '@mui/material/IconButton';
import useTheme from '@mui/material/styles/useTheme';
import { usePathname } from 'next/navigation';
import { AnalyticsEventNames } from '@/analytics/Event.types';
import { AnalyticsPageNames } from '@/analytics/Page.types';
import { analytics } from '@/analytics/segment';
import Link from '@/components/Link';
import { DisplayedDealStatusForBuyer } from '../offers/types';
import { getDisplayedDealStatusForBuyer } from './actions';
import { BuyerOffersList } from './types';

export default function OffersButton(): JSX.Element {
  const pathname = usePathname();
  const theme = useTheme();
  const [buyerOffersList, setBuyerOffersList] = useState<BuyerOffersList>();

  useEffect(() => {
    getDisplayedDealStatusForBuyer().then((list) => setBuyerOffersList(list));
  }, [pathname]);

  const showNeedsReviewDot =
    buyerOffersList && buyerOffersList[DisplayedDealStatusForBuyer.NEEDS_REVIEW] > 0;

  return (
    <IconButton
      LinkComponent={Link}
      href="/offers"
      data-testid="button-offersIcon"
      onClick={() => {
        analytics.track(AnalyticsEventNames.NAV_ICON_CLICKED, {
          to: AnalyticsPageNames.BUYER_OFFERS,
        });
      }}
      sx={{
        '&:hover': {
          backgroundColor: theme.palette.primary.contrastText,
        },
        position: 'relative',
      }}
    >
      <Offers />
      {!!showNeedsReviewDot && (
        <DotFilled
          color="primary"
          sx={{
            fontSize: 8,
            position: 'absolute',
            top: 8,
            right: 8,
            stroke: theme.palette.primary.contrastText,
            strokeWidth: '1px',
          }}
        />
      )}
    </IconButton>
  );
}
