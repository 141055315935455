import { FC } from 'react';
import { Spacing } from '@ghosts-inc/design-system/constants';
import Stack from '@mui/material/Stack';
import CoinLogo from './CoinLogo';
import MyDeals from './MyDeals';
import NavDropdown from './NavDropdown';
import SearchBox from './SearchBox';
import UserMenu from './UserMenu';

interface TabletNavProps {
  isReferralEnabled: boolean;
  isWishlistEnabled: boolean;
}

const TabletNav: FC<TabletNavProps> = ({ isReferralEnabled, isWishlistEnabled }) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      py={Spacing.base}
      px={Spacing.xxlarge}
      gap={Spacing.base}
    >
      <CoinLogo />
      <NavDropdown isWishlistEnabled={isWishlistEnabled} />
      <MyDeals anchorSide="left" />
      <Stack flexGrow={1}>
        <SearchBox />
      </Stack>
      <UserMenu isReferralEnabled={isReferralEnabled} />
    </Stack>
  );
};

export default TabletNav;
