'use client';

import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Image from 'next/image';
import { usePathname } from 'next/navigation';
import Link from '@/components/Link';
import { getCollectionPageLink } from '../../../legacy/listings/ListingsPage/v2/links';
import bannerDesktop from './whatnotBannerDesktop.jpg';
import bannerMobile from './whatnotBannerMobile.jpg';

export const WhatnotBanner = () => {
  const pathname = usePathname();
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

  // only show banner on the homepage
  return pathname === '/' ? (
    <Link href={getCollectionPageLink('ghost-x-whatnot')}>
      <Image src={isDesktop ? bannerDesktop : bannerMobile} alt="Ghost x Whatnot" />
    </Link>
  ) : null;
};
