'use client';

import { FC, use, useEffect, useState } from 'react';
import { Spacing } from '@ghosts-inc/design-system/constants';
import { CaretDown, DotFilled } from '@ghosts-inc/design-system/icons';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import { usePathname } from 'next/navigation';
import { InboxButtonContext } from '@/app/(authenticated)/(marketplace)/HeaderNew/_components/inboxButtonContext';
import MenuItem from '@/components/MenuItem';

const MENU_LINKS: { title: string; href: string }[] = [
  { title: 'Home', href: '/' },
  { title: 'Inbox', href: '/inbox' },
  { title: 'Saved', href: '/saved' },
  { title: 'Wishlist', href: '/wishlist' },
];

const getLabel = (pathname: string, isWishlistEnabled: boolean) => {
  if (pathname.startsWith('/inbox')) {
    return 'Inbox';
  }
  if (pathname.startsWith('/saved') && !isWishlistEnabled) {
    return 'Saved';
  }
  if (pathname.startsWith('/wishlist') && isWishlistEnabled) {
    return 'Wishlist';
  }
  return 'Home';
};

type NavDropdownProps = {
  isWishlistEnabled: boolean;
};

const NavDropdown: FC<NavDropdownProps> = ({ isWishlistEnabled }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { hasUnreadMessages, refresh } = use(InboxButtonContext);
  const open = Boolean(anchorEl);

  const pathname = usePathname();
  const label = getLabel(pathname, isWishlistEnabled);

  useEffect(() => {
    refresh();
  }, [pathname, refresh]);

  const showMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        data-testid="nav-dropdown-button"
        variant="text"
        size="headerLink"
        endIcon={<CaretDown />}
        startIcon={
          hasUnreadMessages ? (
            <DotFilled
              data-testid="inbox-unread-dot"
              color="primary"
              sx={{ width: 8, marginLeft: Spacing.small }}
            />
          ) : undefined
        }
        onClick={showMenu}
      >
        {label}
      </Button>
      <Menu
        data-testid="nav-dropdown-menu"
        open={open}
        onClose={closeMenu}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {MENU_LINKS.filter(
          (link) =>
            (link.title === 'Wishlist' && isWishlistEnabled) ||
            (link.title === 'Saved' && !isWishlistEnabled) ||
            (link.title !== 'Wishlist' && link.title !== 'Saved'),
        ).map(({ href, title }) => (
          <MenuItem href={href} onClick={closeMenu} key={title}>
            {title}
            {title === 'Inbox' && hasUnreadMessages && (
              <DotFilled
                data-testid="inbox-unread-dot"
                sx={(theme) => ({
                  width: 8,
                  marginLeft: Spacing.small,
                  color: theme.palette.newBrandFill.default,
                })}
              />
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default NavDropdown;
