'use client';

import React, { FormEventHandler } from 'react';
import { Search } from '@ghosts-inc/design-system/icons';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { useRouter } from 'next/navigation';

type SearchBoxProps = {
  productsIndexName: string;
  searchTerm: string;
  onChange: (searchTerm: string) => void;
};

/**
 * Because this SearchBox can be used in 2 places, manage state in the parent
 */
const SearchBox = ({ searchTerm, onChange, productsIndexName }: SearchBoxProps) => {
  const router = useRouter();

  const handleSubmit: FormEventHandler = (event) => {
    event.preventDefault();
    const term = searchTerm.trim();
    if (term === '') return;
    router.push(`/search?${productsIndexName}%5Bquery%5D=${term}`);
  };

  return (
    <Box width="100%">
      <form onSubmit={handleSubmit}>
        <TextField
          autoComplete="off"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search htmlColor="black" />
              </InputAdornment>
            ),
          }}
          onChange={(e) => onChange(e.target.value)}
          placeholder="Search brands or products"
          data-testid="input-searchBox"
          size="small"
          fullWidth={true}
          variant="standard"
          value={searchTerm}
        />
      </form>
    </Box>
  );
};

export default SearchBox;
