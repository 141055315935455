import { FC, HTMLAttributeAnchorTarget, PropsWithChildren, use, useEffect, useState } from 'react';
import { Sizes, Spacing } from '@ghosts-inc/design-system/constants';
import { Close, DotFilled, Hamburger } from '@ghosts-inc/design-system/icons';
import { GhostLogoV2EqualHeight } from '@ghosts-inc/design-system/svgs';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MuiLink from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Typography from '@mui/material/Typography';
import { LinkProps as NextLinkProps } from 'next/link';
import { usePathname } from 'next/navigation';
import { AnalyticsEventNames } from '@/analytics/Event.types';
import { analytics } from '@/analytics/segment';
import { InboxButtonContext } from '@/app/(authenticated)/(marketplace)/HeaderNew/_components/inboxButtonContext';
import Link from '@/components/Link';
import { handleSignOut } from '@/components/UserMenu/actions';
import { ReferrerStates } from '@/legacy/referrers/referrerSummary.model';
import { SellerStates } from '@/legacy/sellers/sellerSummary.model';
import { useGhostStore } from '@/legacy/store';
import { CategoryGroup, Collection } from '@/types/collections';
import { CATEGORIES } from './constants';

interface DrawerMenuProps {
  isReferralEnabled: boolean;
  isWishlistEnabled: boolean;
}

const MenuLink: FC<
  PropsWithChildren<
    Pick<NextLinkProps, 'href' | 'onClick'> & {
      target?: HTMLAttributeAnchorTarget;
    }
  >
> = ({ children, href, onClick, target }) => {
  return (
    <Link
      mx={Spacing.small}
      my={Spacing.small12}
      href={href}
      onClick={onClick}
      target={target}
      color="typographyDarkPrimary"
    >
      {children}
    </Link>
  );
};

const DrawerMenu: FC<DrawerMenuProps> = ({ isReferralEnabled, isWishlistEnabled }) => {
  const [open, setOpen] = useState(false);
  const { hasUnreadMessages, refresh } = use(InboxButtonContext);

  const pathname = usePathname();
  useEffect(() => {
    refresh();
  }, [pathname, refresh]);

  const openDrawer = () => {
    setOpen(true);
  };

  const closeDrawer = () => {
    setOpen(false);
  };

  const { user, setUser } = useGhostStore();

  const handleLinkClick = ({
    categoryGroup,
    collection,
    index,
    label,
  }: {
    categoryGroup?: CategoryGroup;
    collection: Collection;
    index: number;
    label: string;
  }) => {
    analytics.track(AnalyticsEventNames.NAV_LINK_CLICKED, {
      collection: collection,
      category_group: categoryGroup,
      display_text: label,
      index,
    });
    closeDrawer();
  };

  const handleLogout = async () => {
    analytics.track(AnalyticsEventNames.LOGOUT_CLICKED);
    await handleSignOut();
    closeDrawer();
    setUser(null);
  };

  const isSeller = user?.seller?.state === SellerStates.ACTIVATED;
  const isReferrer = isReferralEnabled && user?.referrer?.state === ReferrerStates.ACTIVATED;

  return (
    <>
      <IconButton onClick={openDrawer}>
        <Hamburger data-testid="drawer-menu-icon" />
      </IconButton>
      <SwipeableDrawer anchor="right" open={open} onOpen={openDrawer} onClose={closeDrawer}>
        <Stack width="100vw" data-testid="drawer-menu-contents">
          <Stack
            direction="row"
            justifyContent="space-between"
            p={Spacing.base}
            borderBottom="solid 1px #EDEFF3" // TODO: use a variable for this once typography/icon colors are updated
          >
            <IconButton href="/" onClick={closeDrawer}>
              <GhostLogoV2EqualHeight height={Sizes.small} data-testid="drawer-menu-home" />
            </IconButton>
            <IconButton onClick={closeDrawer}>
              <Close data-testid="drawer-menu-close-icon" />
            </IconButton>
          </Stack>
          <Stack px={Spacing.base} py={Spacing.large}>
            <MenuLink href="/" onClick={closeDrawer}>
              Home
            </MenuLink>
            <MenuLink href="/inbox" onClick={closeDrawer}>
              Inbox
              {hasUnreadMessages && (
                <DotFilled
                  data-testid="inbox-unread-dot"
                  sx={(theme) => ({
                    width: 8,
                    marginLeft: Spacing.small,
                    color: theme.palette.newBrandFill.default,
                  })}
                />
              )}
            </MenuLink>
            {!isWishlistEnabled && (
              <MenuLink href="/saved" onClick={closeDrawer}>
                Saved
              </MenuLink>
            )}
            {isWishlistEnabled && (
              <MenuLink href="/wishlist" onClick={closeDrawer}>
                Wishlist
              </MenuLink>
            )}
            <MenuLink href="/offers" onClick={closeDrawer}>
              My Deals
            </MenuLink>
            <Stack my={Spacing.small12}>
              <Divider />
            </Stack>

            {CATEGORIES.map(({ label, href, collection, categoryGroup, icon }, index) => (
              <Link
                href={href}
                key={label}
                onClick={() => handleLinkClick({ index, label, collection, categoryGroup })}
              >
                <Stack direction="row" gap={Spacing.small} px={Spacing.small} py={Spacing.small12}>
                  {icon({
                    sx: {
                      width: Sizes.xsmall,
                      height: Sizes.xsmall,
                    },
                    htmlColor: 'black',
                  })}
                  <Typography>{label}</Typography>
                </Stack>
              </Link>
            ))}
            <Stack my={Spacing.small12}>
              <Divider />
            </Stack>
            <MenuLink href="/preferences" onClick={closeDrawer}>
              Preferences
            </MenuLink>
            {isSeller && (
              <MenuLink href="/seller" target="_blank" onClick={closeDrawer}>
                Switch to Seller
              </MenuLink>
            )}
            {isReferrer && (
              <MenuLink href="/referrer" target="_blank" onClick={closeDrawer}>
                Switch to Referrer
              </MenuLink>
            )}
            <MuiLink
              mx={Spacing.small}
              my={Spacing.small12}
              onClick={handleLogout}
              color="typographyDarkPrimary"
            >
              Sign Out
            </MuiLink>
          </Stack>
        </Stack>
      </SwipeableDrawer>
    </>
  );
};

export default DrawerMenu;
