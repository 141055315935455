'use client';

import { FC } from 'react';
import { type Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Categories from './Categories';
import DesktopNav from './DesktopNav';
import MobileNav from './MobileNav';
import TabletNav from './TabletNav';

interface NavPickerProps {
  isReferralEnabled: boolean;
  isWishlistEnabled: boolean;
}

const NavPicker: FC<NavPickerProps> = ({ isReferralEnabled, isWishlistEnabled }) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('desktop'));
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.only('tablet'));
  const isSmallTabletOrBelow = useMediaQuery((theme: Theme) => theme.breakpoints.down('tablet'));

  if (isSmallTabletOrBelow) {
    return (
      <MobileNav isReferralEnabled={isReferralEnabled} isWishlistEnabled={isWishlistEnabled} />
    );
  }

  return (
    <>
      {isDesktop && (
        <DesktopNav isReferralEnabled={isReferralEnabled} isWishlistEnabled={isWishlistEnabled} />
      )}
      {isTablet && (
        <TabletNav isReferralEnabled={isReferralEnabled} isWishlistEnabled={isWishlistEnabled} />
      )}
      <Categories />
    </>
  );
};

export default NavPicker;
