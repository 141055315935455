import { ListingState } from '@/app/(authenticated)/(marketplace)/listings/types';
import {
  DisplayedPaymentStatus,
  PaymentPlanStatus,
} from '@/app/(authenticated)/(marketplace)/offers/payments/types';
import { ItemizedOfferForListing } from '../listings/types';
import { DealCycleState } from '../sellers/DealDetails/types';

export enum OfferState {
  DRAFT = 'draft',
  SUBMITTED = 'submitted',
}

export enum DisplayedDealStatusBase {
  NEEDS_REVIEW = 'needs_review',
}

export enum DisplayedDealStatusForBuyerBase {
  PENDING = 'pending',
  DRAFT = 'draft',
  COMPLETED = 'completed',
  ARCHIVED = 'archived',
}

// a union of all keys from both enums
export const DisplayedDealStatusForBuyer = {
  ...DisplayedDealStatusBase,
  ...DisplayedDealStatusForBuyerBase,
};

// type for bother enums
export type DisplayedDealStatusForBuyer = DisplayedDealStatusBase | DisplayedDealStatusForBuyerBase;

export type DealsCountForBuyer = {
  [DisplayedDealStatusForBuyer.NEEDS_REVIEW]: number;
  [DisplayedDealStatusForBuyer.PENDING]: number;
  [DisplayedDealStatusForBuyer.DRAFT]: number;
  [DisplayedDealStatusForBuyer.COMPLETED]: number;
  [DisplayedDealStatusForBuyer.ARCHIVED]: number;
};

export type DealCycle = {
  state: DealCycleState;
  sellerId: number;
  buyerId: number;
};

export enum OfferType {
  // BuyerToGhost, GhostToSeller
  INITIAL = 'initial',
  // Ghost as the liaison between buyers and sellers can pass along counters between buyers and sellers
  // (after marking up or down the prices). Ex:
  // {GhostToBuyer, SELLER_COUNTER}, {GhostToSeller, BUYER_COUNTER}.

  // Ghost can also give feedback to buyers/sellers prior to fully showing an offer to the party:
  // {GhostToBuyer, GHOST_COUNTER}, {GhostToSeller, GHOST_COUNTER}.

  // And of course, buyers and sellers can also counter any offers that come to them:
  // {SellerToGhost, SELLER_COUNTER}, {BuyerToGhost, BUYER_COUNTER}.
  BUYER_COUNTER = 'buyer_counter',
  SELLER_COUNTER = 'seller_counter',
  GHOST_COUNTER = 'ghost_counter',
  // These will only come from buyers and sellers
  HOLD = 'hold',
  ACCEPT = 'accept',
}

export type DealCycleForBuyerDashboard = {
  brandDisplay: string;
  dealCycleId: number;
  dealCycleState: DealCycleState;
  paymentPlanStatus?: PaymentPlanStatus;
  displayedPaymentStatus?: DisplayedPaymentStatus;
  paymentPlanLastActionAt?: string;
  largeImageUrl: string;
  latestBuyerToGhostOffer: {
    offerState: OfferState;
    offerType: OfferType;
    totalPriceInCents: number;
    totalQuantity: number;
    updatedAt: string;
    submittedAt: string | null;
  };
  latestGhostToBuyerOffer: {
    offerState: OfferState;
    offerType: OfferType;
    totalPriceInCents: number;
    totalQuantity: number;
    submittedAt: string;
  } | null;
  listingId: number;
  listingTitle: string;
  numberOfProducts: number;
};
