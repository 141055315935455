import { FC } from 'react';
import { Spacing } from '@ghosts-inc/design-system/constants';
import Stack from '@mui/material/Stack';
import MyDeals from './MyDeals';
import NavButtons from './NavButtons';
import SearchBox from './SearchBox';
import UserMenu from './UserMenu';

interface DesktopNavProps {
  isReferralEnabled: boolean;
  isWishlistEnabled: boolean;
}

const DesktopNav: FC<DesktopNavProps> = ({ isReferralEnabled, isWishlistEnabled }) => {
  return (
    <Stack
      direction="row"
      py={Spacing.base}
      px={10}
      justifyContent="space-between"
      alignItems="center"
      gap={Spacing.small}
    >
      <Stack direction="row" gap={Spacing.small}>
        <NavButtons isWishlistEnabled={isWishlistEnabled} />
      </Stack>
      <Stack flexGrow={1} maxWidth={450}>
        <SearchBox />
      </Stack>
      <Stack direction="row" gap={Spacing.small}>
        <MyDeals />
        <UserMenu isReferralEnabled={isReferralEnabled} />
      </Stack>
    </Stack>
  );
};

export default DesktopNav;
