'use client';

import React, { useEffect, useState } from 'react';
import { Close, Hamburger } from '@ghosts-inc/design-system/icons';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MuiLink from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { usePathname } from 'next/navigation';
import { AnalyticsEventNames } from '@/analytics/Event.types';
import { analytics } from '@/analytics/segment';
import { useBuyerRecommendationsFeatureFlag } from '@/app/(authenticated)/(marketplace)/preferences/hooks/featureFlags';
import Link from '@/components/Link';
import { handleSignOut } from '@/components/UserMenu/actions';
import { useIsDisplayBuyerLink } from '@/components/UserMenu/hook/useIsDisplayBuyerLink';
import { useIsDisplayReferrerLink } from '@/components/UserMenu/hook/useIsDisplayReferrerLink';
import { useIsDisplaySellerLink } from '@/components/UserMenu/hook/useIsDisplaySellerLink';
import { isReferralsEnabled } from '@/launchdarkly/actions';
import { BuyerStates } from '@/legacy/buyers/buyerSummary.model';
import { useGhostStore } from '@/legacy/store';
import { MENU_ITEMS, makeLink } from '../CategoryBanner/menuItems';

const CategoryMenu = () => {
  const { user, setUser } = useGhostStore((state) => state);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isReferralEnabled, setIsReferralEnabled] = useState(false);

  const handleMenuClick = () => {
    setIsDrawerOpen(true);
  };

  const handleClose = () => {
    setIsDrawerOpen(false);
  };

  const handleLinkItemClick = (
    args: (typeof MENU_ITEMS)[0] & {
      index?: number;
    },
  ) => {
    analytics.track(AnalyticsEventNames.NAV_LINK_CLICKED, {
      collection: args.collection,
      category_group: args.categoryGroup,
      display_text: args.displayText,
      index: args.index,
    });
    // Close menu
    handleClose();
  };

  const onLogoutClick = async () => {
    analytics.track(AnalyticsEventNames.LOGOUT_CLICKED);
    await handleSignOut();
    setUser(null);
  };

  useEffect(() => {
    isReferralsEnabled().then(setIsReferralEnabled);
  }, []);

  const showSellerLink = useIsDisplaySellerLink();
  const showBuyerLink = useIsDisplayBuyerLink();
  const showReferrerLink = useIsDisplayReferrerLink(isReferralEnabled);
  const { isBuyerRecommendationsEnabled } = useBuyerRecommendationsFeatureFlag();
  const pathname = usePathname();

  return (
    <>
      <IconButton onClick={handleMenuClick}>
        <Hamburger />
      </IconButton>
      <SwipeableDrawer
        anchor={'right'}
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        onOpen={() => setIsDrawerOpen(true)}
      >
        <Stack
          direction="column"
          gap={4}
          height="100vh"
          width={{ mobile: '100vw' }}
          sx={{ overflowY: 'auto', overflowX: 'hidden' }}
          paddingX={2}
          paddingTop={3}
        >
          <Stack alignItems={'end'}>
            <IconButton onClick={() => setIsDrawerOpen(false)}>
              <Close />
            </IconButton>
          </Stack>
          {MENU_ITEMS.map((linkProps, index) => {
            const link = makeLink(linkProps.collection, linkProps.categoryGroup);
            return (
              <Link
                key={linkProps.displayText}
                underline="none"
                href={link}
                onClick={() => handleLinkItemClick({ ...linkProps, index })}
                variant="linkCaps"
              >
                {linkProps.displayText}
              </Link>
            );
          })}
          <Divider />

          {!pathname.startsWith('/referrer') &&
            isBuyerRecommendationsEnabled &&
            !pathname.startsWith('/seller') && (
              <MuiLink
                href="/preferences"
                onClick={handleClose}
                rel="noopener noreferrer"
                underline="none"
                variant="linkCaps"
              >
                PREFERENCES
              </MuiLink>
            )}
          {showSellerLink && (
            <MuiLink
              href="/seller"
              onClick={handleClose}
              rel="noopener noreferrer"
              target="_blank"
              underline="none"
              variant="linkCaps"
            >
              SELLER
            </MuiLink>
          )}
          {showBuyerLink && user && (
            <MuiLink
              href={user.buyer?.state === BuyerStates.ACTIVATED ? '/' : '/onboarding/buyer'}
              onClick={handleClose}
              rel="noopener noreferrer"
              target="_blank"
              underline="none"
              variant="linkCaps"
            >
              {user.buyer?.state === BuyerStates.ACTIVATED ? 'BUYER' : 'CONTINUE BUYER ONBOARDING'}
            </MuiLink>
          )}
          {showReferrerLink && (
            <MuiLink
              href="/referrer"
              onClick={handleClose}
              rel="noopener noreferrer"
              underline="none"
              variant="linkCaps"
            >
              REFERRER
            </MuiLink>
          )}
          <MuiLink
            underline="none"
            onClick={() => {
              onLogoutClick();
            }}
            variant="linkCaps"
          >
            LOG OUT
          </MuiLink>
        </Stack>
      </SwipeableDrawer>
    </>
  );
};

export default CategoryMenu;
