'use client';

import { useEffect } from 'react';
import { useRouter } from 'next/navigation';

interface LayoutRedirectorProps {
  redirectUserCheckPath?: string;
}

export const LayoutRedirector = ({ redirectUserCheckPath }: LayoutRedirectorProps) => {
  const { push } = useRouter();

  useEffect(() => {
    push(redirectUserCheckPath || '/');
  }, [push, redirectUserCheckPath]);

  return null;
};
