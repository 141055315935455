'use client';

import { useEffect } from 'react';
import { BuyerPreferences } from '@/app/(authenticated)/(marketplace)/_components/types';
import { useBuyerRecommendationsFeatureFlag } from '@/app/(authenticated)/(marketplace)/preferences/hooks/featureFlags';
import { useGhostStore } from '@/legacy/store';

export const BuyerPreferencesInit = ({
  buyerPreferences: fetchedBuyerPreferences,
}: {
  buyerPreferences: BuyerPreferences;
}) => {
  const { isBuyerRecommendationsEnabled } = useBuyerRecommendationsFeatureFlag();

  const { buyerPreferences: storedBuyerPreferences, setBuyerPreferences } = useGhostStore(
    (store) => store,
  );

  useEffect(() => {
    if (isBuyerRecommendationsEnabled && fetchedBuyerPreferences && !storedBuyerPreferences) {
      setBuyerPreferences(fetchedBuyerPreferences);
    }
  }, [
    isBuyerRecommendationsEnabled,
    fetchedBuyerPreferences,
    setBuyerPreferences,
    storedBuyerPreferences,
  ]);

  return null;
};
