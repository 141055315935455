'use client';

import { FC } from 'react';
import { Sizes, Spacing } from '@ghosts-inc/design-system/constants';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { type Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import Collapser from './Collapser';
import { CATEGORIES } from './constants';

const CategoryList: FC<{ showTallCategories: boolean }> = ({ showTallCategories }) => {
  const iconSize = showTallCategories ? Sizes.small : Sizes.xsmall;

  return (
    <>
      {CATEGORIES.map(({ label, href, icon }) => (
        <Link href={href} key={label}>
          <Stack
            width={{ smallTablet: 80, desktop: 'unset' }}
            direction={showTallCategories ? 'column' : 'row'}
            py={Spacing.small}
            alignItems="center"
            gap={showTallCategories ? Spacing.xxsmall : Spacing.xsmall}
            sx={(theme) => ({
              '&:hover': {
                color: theme.palette.typographyDarkSecondary.main,
                '.MuiTypography-root': {
                  color: theme.palette.typographyDarkSecondary.main,
                },
              },
            })}
          >
            {icon({
              sx: {
                width: iconSize,
                height: iconSize,
              },
            })}

            <Typography>{label}</Typography>
          </Stack>
        </Link>
      ))}
    </>
  );
};

const Categories: FC = () => {
  const pathname = usePathname();

  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.only('tablet'));

  const isSmallTabletOrBelow = useMediaQuery((theme: Theme) => theme.breakpoints.down('tablet'));

  const showCategories =
    pathname === '/' || pathname.startsWith('/collection') || pathname.startsWith('/search');

  if (!showCategories || isSmallTabletOrBelow) return null;

  const showTallCategories = pathname === '/' || isTablet;

  return (
    <Collapser>
      <Stack
        direction="row"
        justifyContent="center"
        gap={showTallCategories ? Spacing.large : Spacing.xxlarge}
        pt={Spacing.small}
        pb={Spacing.base}
        px={{ smallTablet: 10, desktop: 'unset' }}
        minWidth={{ smallTablet: '100vw', desktop: 'unset' }}
      >
        {isTablet ? (
          <CategoryList showTallCategories={showTallCategories} />
        ) : (
          <Stack direction="row" minWidth={900} justifyContent="space-between">
            <CategoryList showTallCategories={showTallCategories} />
          </Stack>
        )}
      </Stack>
    </Collapser>
  );
};

export default Categories;
