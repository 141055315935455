'use client';

import { use } from 'react';
import { Box as InboxIcon } from '@ghosts-inc/design-system/icons';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { AnalyticsEventNames } from '@/analytics/Event.types';
import { AnalyticsPageNames } from '@/analytics/Page.types';
import { analytics } from '@/analytics/segment';
import { BuyerListingRecommendations } from '@/app/(authenticated)/(marketplace)/inbox/types';
import Link from '@/components/Link';

export default function InboxButton({
  recommendationsPromise,
}: {
  recommendationsPromise: Promise<BuyerListingRecommendations[]>;
}): JSX.Element {
  const recommendations = use(recommendationsPromise);
  let unvisitedListingsCount: number = 0;
  recommendations.forEach((r) => {
    const unvisited =
      !r.lastSeenAt || new Date(r.lastSeenAt).getTime() < new Date(r.createdAt).getTime();
    if (unvisited) {
      unvisitedListingsCount++;
    }
  });

  const dotStyle = {
    position: 'absolute',
    top: '3px',
    right: '3px',
    backgroundColor: '#6829ee',
    borderRadius: '50%',
    width: '12px',
    height: '12px',
    border: '1px solid white',
  };

  return (
    <IconButton
      LinkComponent={Link}
      href="/inbox"
      onClick={() => {
        analytics.track(AnalyticsEventNames.NAV_ICON_CLICKED, {
          to: AnalyticsPageNames.BUYER_INBOX,
        });
      }}
      sx={{
        '&:hover': {
          backgroundColor: 'surface.lighter',
        },
      }}
    >
      <InboxIcon />
      {unvisitedListingsCount > 0 && <Box sx={dotStyle} />}
    </IconButton>
  );
}
