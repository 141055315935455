import { FC } from 'react';
import { Sizes } from '@ghosts-inc/design-system/constants';
import { GhostCoin } from '@ghosts-inc/design-system/icons';
import Link from 'next/link';

interface CoinLogoProps {
  size?: Sizes;
}

const CoinLogo: FC<CoinLogoProps> = ({ size = Sizes.large }) => {
  return (
    <Link href="/">
      <GhostCoin sx={{ width: size, height: size }} />
    </Link>
  );
};

export default CoinLogo;
