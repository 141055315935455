'use client';

import { FC, useEffect, useState } from 'react';
import { CaretDown, DotFilled } from '@ghosts-inc/design-system/icons';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import MenuItem, { MenuItemProps } from '@/components/MenuItem';
import { getDisplayedDealStatusForBuyer } from '../../HeaderOld/actions';
import { DisplayedDealStatusForBuyer } from '../../offers/types';

type BuyerOffersList = Record<DisplayedDealStatusForBuyer, number>;

const OFFERS_PAGE_LIST: Record<DisplayedDealStatusForBuyer, string> = {
  [DisplayedDealStatusForBuyer.NEEDS_REVIEW]: 'Action Required',
  [DisplayedDealStatusForBuyer.DRAFT]: 'Offers in Draft',
  [DisplayedDealStatusForBuyer.PENDING]: 'Pending Offers',
  [DisplayedDealStatusForBuyer.COMPLETED]: 'Completed Offers',
  [DisplayedDealStatusForBuyer.ARCHIVED]: 'Archived',
};

const DEFAULT_OFFERS_COUNT: BuyerOffersList = {
  [DisplayedDealStatusForBuyer.NEEDS_REVIEW]: 0,
  [DisplayedDealStatusForBuyer.DRAFT]: 0,
  [DisplayedDealStatusForBuyer.PENDING]: 0,
  [DisplayedDealStatusForBuyer.COMPLETED]: 0,
  [DisplayedDealStatusForBuyer.ARCHIVED]: 0,
};

const DealMenuItem: FC<
  Omit<MenuItemProps, 'href'> & {
    title: string;
    count: number;
    status: DisplayedDealStatusForBuyer;
  }
> = ({ title, status, count, onClick }) => {
  return (
    <MenuItem href={`/offers?status=${status}`} onClick={onClick}>
      <Stack direction="row" width={220} justifyContent="space-between">
        <Typography>{title}</Typography>
        <Typography>{count}</Typography>
      </Stack>
    </MenuItem>
  );
};

const MyDeals: FC<{ anchorSide?: 'left' | 'right' }> = ({ anchorSide = 'right' }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const refresh = async () => {
    const updatedList = await getDisplayedDealStatusForBuyer();
    setBuyerOffersList(updatedList);
  };

  const showMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    refresh();
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const [buyerOffersList, setBuyerOffersList] = useState<BuyerOffersList>(DEFAULT_OFFERS_COUNT);

  useEffect(() => {
    refresh();
  }, []);

  const needsReview = buyerOffersList[DisplayedDealStatusForBuyer.NEEDS_REVIEW] > 0;

  return (
    <>
      <Button
        variant="text"
        size="headerLink"
        startIcon={
          needsReview && (
            <DotFilled data-testid="needs-review-dot" color="primary" sx={{ width: 8 }} />
          )
        }
        endIcon={<CaretDown />}
        onClick={showMenu}
      >
        My Deals
      </Button>
      <Menu
        data-testid="my-deals-menu"
        open={open}
        onClose={closeMenu}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: anchorSide,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: anchorSide,
        }}
      >
        {Object.entries(OFFERS_PAGE_LIST).map(([offerState, offerStateLabel]) => {
          const count = buyerOffersList[offerState as DisplayedDealStatusForBuyer];

          // We don't want to show the "Needs Review" tab if there are no offers that need review
          if (offerState === DisplayedDealStatusForBuyer.NEEDS_REVIEW && !needsReview) return;

          return (
            <DealMenuItem
              count={count}
              key={offerState}
              status={offerState as DisplayedDealStatusForBuyer}
              title={offerStateLabel}
              onClick={closeMenu}
            />
          );
        })}
      </Menu>
    </>
  );
};

export default MyDeals;
