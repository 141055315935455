import {
  CategoryAccessories,
  CategoryApparel,
  CategoryBeauty,
  CategoryFootwear,
  CategoryHome,
  CollectionFeatured,
  CollectionNew,
  CollectionTrending,
} from '@ghosts-inc/design-system/icons';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import { CategoryGroup, Collection } from '@/types/collections';

export const CATEGORIES: {
  icon: (props: SvgIconProps) => JSX.Element;
  label: string;
  href: string;
  collection: Collection;
  categoryGroup?: CategoryGroup;
}[] = [
  { icon: CollectionNew, label: 'New', href: '/collection/new', collection: 'new' },
  {
    icon: CollectionFeatured,
    label: 'Featured',
    href: '/collection/featured',
    collection: 'featured',
  },
  {
    icon: CollectionTrending,
    label: 'Trending',
    href: '/collection/trending',
    collection: 'trending',
  },
  {
    icon: CategoryFootwear,
    label: 'Footwear',
    href: '/collection/all/footwear',
    collection: 'all',
    categoryGroup: 'footwear',
  },
  {
    icon: CategoryApparel,
    label: 'Apparel',
    href: '/collection/all/apparel',
    collection: 'all',
    categoryGroup: 'apparel',
  },
  {
    icon: CategoryBeauty,
    label: 'Beauty',
    href: '/collection/all/beauty',
    collection: 'all',
    categoryGroup: 'beauty',
  },
  {
    icon: CategoryAccessories,
    label: 'Accessories',
    href: '/collection/all/accessories',
    collection: 'all',
    categoryGroup: 'accessories',
  },
  {
    icon: CategoryHome,
    label: 'Home',
    href: '/collection/all/housewares',
    collection: 'all',
    categoryGroup: 'housewares',
  },
];
