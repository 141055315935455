export enum AnalyticsPageNames {
  BUYER_DASHBOARD = 'Buyer Dashboard',
  BUYER_INBOX = 'Buyer Inbox',
  BUYER_LISTING_DETAILS = 'Buyer Listing Details',
  BUYER_OFFERS = 'Buyer Offers',
  BUYER_FAVORITES = 'Buyer Favorites',
  SELLER_DASHBOARD = 'Seller Dashboard',
  SELLER_LISTINGS = 'Seller Listings',
  SELLER_LISTING_DETAILS = 'Seller Listing Details',
  SELLER_DEAL_DETAILS = 'Seller Deal Details',
  SELLER_DEAL_STYLE = 'Seller Deal Style',
  SELLER_LOT_FORM = 'Seller Lot Form',
  // v2
  BUYER_HOME = 'Buyer Home',
  BUYER_SEARCH = 'Buyer Search',
  BUYER_FILTER = 'Buyer Filter',
  BUYER_CONFIRM_OFFER = 'Buyer Confirm Offer',
  BUYER_OFFER_DETAILS = 'Buyer Offer Details',
  BUYER_OFFER_PAYMENT = 'Buyer Offer Payment',
  BUYER_SUBMITTED_OFFER = 'Buyer Submitted Offer',
}
