import { FC } from 'react';
import { Sizes, Spacing } from '@ghosts-inc/design-system/constants';
import { GhostLogoV2EqualHeight } from '@ghosts-inc/design-system/svgs';
import Stack from '@mui/material/Stack';
import Link from 'next/link';
import Collapser from './Collapser';
import DrawerMenu from './DrawerMenu';
import SearchBox from './SearchBox';

interface MobileNavProps {
  isReferralEnabled: boolean;
  isWishlistEnabled: boolean;
}

const MobileNav: FC<MobileNavProps> = ({ isReferralEnabled, isWishlistEnabled }) => {
  return (
    <Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        py={Spacing.base}
        px={Spacing.base}
        gap={Spacing.small}
      >
        <Link href="/">
          <GhostLogoV2EqualHeight height={Sizes.small} />
        </Link>
        <DrawerMenu isReferralEnabled={isReferralEnabled} isWishlistEnabled={isWishlistEnabled} />
      </Stack>
      <Collapser>
        <Stack px={Spacing.large} pt={Spacing.xsmall} pb={Spacing.small12}>
          <SearchBox />
        </Stack>
      </Collapser>
    </Stack>
  );
};

export default MobileNav;
