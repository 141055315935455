'use client';

import { FC, PropsWithChildren } from 'react';
import { Duration, Easing } from '@ghosts-inc/design-system/constants';
import Collapse from '@mui/material/Collapse';
import useScrollTrigger from '@mui/material/useScrollTrigger';

const Collapser: FC<PropsWithChildren> = ({ children }) => {
  const trigger = useScrollTrigger();

  return (
    <Collapse in={!trigger} easing={Easing.slow} timeout={Duration.t3}>
      {children}
    </Collapse>
  );
};

export default Collapser;
